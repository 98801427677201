export const defaultCurrency = 'EUR'

export const getCurrencySymbol = (currencyCode: string | undefined, locale: string) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  // Format a zero amount to extract the currency symbol
  const parts = formatter.formatToParts(0)

  // Find the currency part in the formatted parts
  return parts.find((part) => part.type === 'currency')!.value
}
