<script setup lang="ts">
  import type { ContentAlignement } from '~/types/design-system'
  import type { Reward } from '~/types/reward'

  const { companyId } = useRoute().params
  const { isPending, data } = useStoreReward().getRewards(parseInt(companyId as string))

  const rewards = computed((): Reward[] => {
    if (!data.value) {
      return []
    }

    const rewards = data.value as Reward[]
    return rewards.filter(filterActiveReward)
  })

  const filterActiveReward = (reward: Reward) => {
    const { promo_type, validity_date, is_birthday, is_soldout } = reward

    if (promo_type === 'GIF' || is_birthday || is_soldout) return false

    const dayjs = useDayjs()
    return validity_date === null || dayjs(validity_date) >= dayjs()
  }

  const section = computed(() => {
    const { brandings } = useStoreBranding()

    if (!brandings) return

    const { extractTypography } = useBrandingTypography()
    const {
      id,
      section_color,
      layout,
      section_title: { alignment }
    } = brandings.branding_reward

    return {
      id,
      isCarouselLayout: isCarouselLayout(layout),
      backgroundColor: section_color,
      title: extractTypography('branding_reward', 'section_title', 'primary'),
      alignment
    }
  })

  const titleAlignment = computed((): ContentAlignement => {
    if (!section.value) return 'left'

    return getContentAlignment(section.value.alignment)
  })

  const showAllModal = ref(false)
  const hasOpenedAnchor = ref(false)
</script>

<template>
  <SectionAnchored
    anchor-id="rewards"
    anchor-id-modal="reward"
  >
    <SectionBase
      v-if="section"
      :id="section.id"
      class="rewards-wrapper"
      :background-color="section.backgroundColor"
    >
      <SectionSkeletonTitle v-if="isPending" />
      <div
        v-else
        class="rewards-wrapper__title"
      >
        <TypographyBase
          :id="section.title.id"
          tag="h2"
          class="rewards-wrapper__title--text text-primary-heading-2"
          :font-weight="section.title.fontWeight"
          :font-size="section.title.fontSize"
          :color="section.title.color"
          :font-family="section.title.fontFamily"
          :text-align="section.title.textAlign"
        >
          {{ section.title.text }}
        </TypographyBase>

        <ButtonShowAll
          v-if="section.isCarouselLayout"
          @click="showAllModal = true"
        >
          {{ $t('SectionRewards.showAll') }}
        </ButtonShowAll>

        <ModalShowAll
          :show="showAllModal"
          :title="section.title"
          @close="showAllModal = false"
        >
          <div class="rewards__modal-show-all-grid">
            <SectionRewardsCardList
              v-if="rewards.length > 0"
              :anchor-ignore="hasOpenedAnchor"
              :rewards="rewards"
            />
            <SectionEmpty v-else>{{ $t('SectionRewards.empty') }}</SectionEmpty>
          </div>
        </ModalShowAll>
      </div>

      <SectionEmpty v-if="!isPending && !rewards.length">{{ $t('SectionRewards.empty') }}</SectionEmpty>

      <CarouselBase
        v-if="section.isCarouselLayout"
        class="rewards-wrapper__carousel"
        buttons-background-color="#000"
        buttons-hover-background-color="#000"
        buttons-text-color="#fff"
        buttons-hover-text-color="#fff"
        :buttons-radius="0"
        :slide-width="300"
      >
        <template v-if="isPending">
          <SectionRewardsCardSkeleton />
          <SectionRewardsCardSkeleton />
          <SectionRewardsCardSkeleton />
        </template>
        <SectionRewardsCardList
          v-else-if="rewards.length > 0"
          :anchor-ignore="hasOpenedAnchor"
          :rewards="rewards"
          @open-anchor="hasOpenedAnchor = true"
        />
      </CarouselBase>
      <div
        v-else
        class="rewards-wrapper__container"
      >
        <template v-if="isPending">
          <SectionRewardsCardSkeleton />
          <SectionRewardsCardSkeleton />
          <SectionRewardsCardSkeleton />
          <SectionRewardsCardSkeleton />
          <SectionRewardsCardSkeleton />
          <SectionRewardsCardSkeleton />
        </template>
        <SectionRewardsCardList
          v-else-if="rewards.length > 0"
          :anchor-ignore="hasOpenedAnchor"
          :rewards="rewards"
          @open-anchor="hasOpenedAnchor = true"
        />
      </div>
    </SectionBase>
  </SectionAnchored>
</template>

<style scoped>
  .rewards-wrapper {
    :deep(.lds-section__content) {
      display: flex;
      flex-direction: column;
      gap: 20px;

      @media (min-width: 768px) {
        gap: 40px;
      }
    }

    &__title {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: v-bind(titleAlignment);

      &--text {
        width: auto;
      }
    }

    &__carousel {
      height: 318px; /* card height */

      @media (min-width: 768px) {
        height: 550px;
      }
    }

    &__container {
      display: grid;
      place-content: center;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

      @media (min-width: 480px) {
        column-gap: 40px;
      }

      @media (min-width: 768px) {
        gap: 70px;
      }

      @media (min-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 42.5px;
      }

      @media (min-width: 1440px) {
        gap: 70px;
      }
    }
  }
  .rewards__modal-show-all-grid {
    display: grid;
    place-content: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    @media (min-width: 480px) {
      column-gap: 40px;
    }

    @media (min-width: 768px) {
      gap: 70px;
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, 1fr);
      gap: 12.5px;
    }

    @media (min-width: 1440px) {
      gap: 40px;
    }
  }
</style>
