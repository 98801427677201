<script setup lang="ts">
  import { useStoreVip } from '~/stores/useStoreVip'

  const companyId = parseInt(useRoute().params.companyId as string, 10)

  // Check if app is embedded inside an iframe
  if (useRoute().query.embedded || window !== window.parent) {
    useStoreApp().isAppEmbedded = true
  }
  const { isAuthenticated } = storeToRefs(useStoreAuth())
  const { brandings } = storeToRefs(useStoreBranding())
  const { company } = storeToRefs(useStoreCompany())
  const { data: vipLevels } = useStoreVip().getVipLevels(companyId)
  const { data: referralProgram } = useStoreReferral().getReferralProgram(companyId)
  const { getCreatorBalance } = useStoreCreator()
  const { data: balance, refetch: fetchBalance } = getCreatorBalance(companyId)

  onMounted(() => {
    fetchBalance().then(() => {
      useTrack().track('page_loyalty', {
        loyalty_points: balance.value || 0
      })
    })
  })

  const sectionsOrdered = computed(() => {
    if (!brandings.value) return

    const { branding_reward, branding_challenge, branding_vip, branding_gifting, branding_referral } = brandings.value

    const sections = []

    if (branding_reward) sections.push({ name: "'reward'", order: branding_reward.display_order })
    if (branding_challenge) sections.push({ name: "'challenge'", order: branding_challenge.display_order })
    if (branding_vip) sections.push({ name: "'vip'", order: branding_vip.display_order })
    if (branding_gifting) sections.push({ name: "'gifting'", order: branding_gifting.display_order })
    if (branding_referral) sections.push({ name: "'referral'", order: branding_referral.display_order })

    return sections
      .sort((s1, s2) => s1.order - s2.order)
      .map((section) => section.name)
      .join(' ')
  })
</script>

<template>
  <div id="container-sections">
    <ClientOnly>
      <Teleport
        v-if="!isAuthenticated"
        to="#header-buttons"
      >
        <LoginOrRegister
          track-section="FU-Banner"
          handle-modals
        />
      </Teleport>
    </ClientOnly>
    <SectionSteps v-if="brandings?.branding_step.display" />
    <div class="sections-ordered">
      <SectionChallenges
        v-if="brandings?.branding_challenge.display"
        class="section-challenges"
      />
      <SectionRewards
        v-if="brandings?.branding_reward.display"
        class="section-rewards"
      />
      <SectionReferralCard
        v-if="
          company?.settings.features.referral &&
          referralProgram &&
          referralProgram.enable &&
          brandings?.branding_referral?.display
        "
        :referral-program="referralProgram"
        class="section-referral"
      />
      <SectionVIP
        v-if="
          company?.settings.features.vip_tiers && vipLevels && vipLevels?.length > 0 && brandings?.branding_vip?.display
        "
        class="section-vip"
      />
      <SectionGifting
        v-if="company?.settings.features.product_test && brandings?.branding_gifting?.display"
        class="section-gifting"
      />
    </div>
    <SectionProfile v-if="isAuthenticated && brandings?.branding_profil.display" />
  </div>
</template>

<style scoped>
  .sections-ordered {
    display: grid;
    grid-template-areas: v-bind(sectionsOrdered);
  }

  .section-challenges {
    grid-area: challenge;
  }

  .section-rewards {
    grid-area: reward;
  }

  .section-referral {
    grid-area: referral;
  }

  .section-vip {
    grid-area: vip;
  }

  .section-gifting {
    grid-area: gifting;
  }
</style>
