<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import type { Challenge, ChallengeDisplay } from '~/types/challenge'

  const company = useStoreCompany().company
  const storeStore = useStoreStore()
  const { companyId } = useStoreApp()
  const { data } = useStoreCompany().getOrders(companyId)

  const props = withDefaults(
    defineProps<{
      challenge: Challenge
      show: boolean
      openFromAnchor: boolean
    }>(),
    {
      openFromAnchor: false
    }
  )

  const emit = defineEmits<{
    close: []
  }>()

  onMounted(() => {
    useTrack().track('modal-missions-details', {
      mission_id: props.challenge.id,
      mission_name: props.challenge.name,
      mission_status: props.challenge.status as string,
      template_id: props.challenge.challenge_template_id,
      type_challenge: props.challenge.type_challenge,
      social_network: props.challenge.social_network
    })
  })

  const getHowTo = computed((): ChallengeDisplay => {
    const text = ['howtobuy', 'howtoVerifySale']
    if (!company!.include_taxes && !company!.include_shipping) {
      text.push('howtoWinPointHT')
    } else if (company!.include_taxes && !company!.include_shipping) {
      text.push('howtoWinPointTaxe')
    } else if (!company!.include_taxes && company!.include_shipping) {
      text.push('howtoWinPointShipping')
    } else {
      text.push('howtoWinPointBoth')
    }
    return {
      display_type: 'howtoLoyalty',
      text
    }
  })

  const { modalsOpenedCount, isModalFullPage, isMobile } = storeToRefs(useStoreApp())
  const footerRef = ref()

  const shouldTeleportBtnsToFooter = computed(() => {
    return footerRef.value && isMobile.value
  })

  const { locale } = useI18n()
  const money = computed(() => {
    return getCurrencySymbol(storeStore.currencyCode, locale.value)
  })
</script>

<template>
  <ModalApp
    class="challenge-modal"
    :class="{ 'child-modal': !openFromAnchor && modalsOpenedCount > 0 && !isModalFullPage && !isMobile }"
    footer-fixed
    :show
    :no-footer="!isMobile"
    @close="emit('close')"
  >
    <div class="challenge-modal__title">
      <img
        v-if="!challenge.background_desktop_url"
        :src="challenge.icon_url"
        width="40"
        height="40"
      />
      <span class="text-primary-heading-1">{{ challenge.name }}</span>
    </div>
    <div class="challenge-modal__content">
      <div class="info text-primary-heading-2">
        {{
          $t('SectionChallenges.ModalMissionLoyaltyDetail.pointsToEarn', {
            money
          })
        }}
        <span>
          {{ $t('Points', challenge.points_value || 0) }}
        </span>
      </div>
      <SectionChallengesDisplayDescription :display="{ display_type: 'desc', value: challenge.description }" />
      <SectionChallengesDisplayHowtoLoyalty
        :display="getHowTo"
        :money
      />
      <SectionChallengesOrderItem
        v-for="order in data"
        :key="order.external_id"
        :order="order"
      />
      <div class="challenge-modal__btn">
        <ClientOnly>
          <Teleport
            :to="footerRef"
            :disabled="!shouldTeleportBtnsToFooter"
          >
            <SectionChallengesDisplayLink
              :display="{ display_type: 'link', value: company?.website_url, text: 'goToShop' }"
            />
          </Teleport>
        </ClientOnly>
      </div>
    </div>
    <template #footer>
      <div
        ref="footerRef"
        class="challenge-modal__footer"
      />
    </template>
  </ModalApp>
</template>
<style scoped>
  .challenge-modal {
    .info {
      padding: 20px 0px;
      border-bottom: 1px solid var(--gray-100);

      span {
        margin-left: 10px;
        font-weight: 400;
      }
    }
    :deep(.lds-modal__content) {
      overflow-y: visible;
    }

    &__title {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    &__btn {
      margin-top: 40px;
      display: flex;
      justify-content: center;

      > button {
        max-width: 50%;
      }
    }

    &__footer {
      width: 100%;
    }
  }

  .child-modal {
    :deep(.lds-modal__container) {
      left: unset;
      top: unset;
      transform: unset;
    }
  }
</style>
