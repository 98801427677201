<script setup lang="ts">
  const { company } = storeToRefs(useStoreCompany())

  const showModalNotifications = ref(false)

  const { isPending } = useStoreCreator().fetchCreator()

  const section = computed(() => {
    const { brandings } = useStoreBranding()

    if (!brandings) return

    const { extractTypography } = useBrandingTypography()
    const { id, section_color } = brandings.branding_profil

    return {
      id,
      backgroundColor: section_color,
      title: extractTypography('branding_profil', 'section_title', 'primary')
    }
  })

  const { isEmbedded, hasCustomerId } = useEmbedded()
</script>

<template>
  <SectionAnchored anchor-id="profile">
    <SectionBase
      v-if="section"
      :id="section.id"
      class="section-profile"
      :background-color="section.backgroundColor"
    >
      <div class="section-profile__header">
        <TypographyBase
          :id="section.title.id"
          tag="h2"
          class="text-primary-heading-2"
          :font-weight="section.title.fontWeight"
          :font-size="section.title.fontSize"
          :color="section.title.color"
          :text-align="section.title.textAlign"
          :font-family="section.title.fontFamily"
        >
          {{ section.title.text }}
        </TypographyBase>
        <SectionProfileLangSelector v-if="useStoreStore().langCode === ''" />
      </div>

      <div class="section-profile__settings">
        <div class="section-profile__settings--networks">
          <SectionProfileButtonInstagram />
          <SectionProfileButtonTiktok />
        </div>

        <div
          v-if="(!isPending && !isEmbedded) || (isEmbedded && !hasCustomerId)"
          class="section-profile__settings--account"
        >
          <SectionProfileFirstname />
          <SectionProfileEmail />
          <SectionProfilePassword />
        </div>
        <div class="section-profile__settings--notifications-links">
          <hr class="section-profile__settings--notifications-links--line" />
          <button
            v-if="company?.settings.features.optin_section"
            class="text-secondary-heading-2 section-profile__settings--notifications-links--button"
            @click="showModalNotifications = true"
          >
            {{ $t('SectionProfile.notificationsByEmails') }}
            <IconChevronRight class="section-profile__settings--notifications-links--chevron" />
          </button>
          <a
            v-if="!isEmbedded"
            class="text-secondary-heading-2 section-profile__settings--notifications-links--link"
            target="_blank"
            href="https://assets.loyoly.io/legal/common/cgu.pdf"
          >
            {{ $t('SectionProfile.cgu') }}
            <IconChevronRight class="section-profile__settings--notifications-links--chevron" />
          </a>
          <a
            v-if="!isEmbedded"
            class="text-secondary-heading-2 section-profile__settings--notifications-links--link"
            target="_blank"
            href="https://assets.loyoly.io/legal/common/privacy.pdf"
          >
            {{ $t('SectionProfile.privacy') }}
            <IconChevronRight class="section-profile__settings--notifications-links--chevron" />
          </a>
        </div>
      </div>
      <ModalNotifications
        :show="showModalNotifications"
        @close="showModalNotifications = false"
      />
    </SectionBase>
  </SectionAnchored>
</template>

<style scoped>
  .section-profile {
    &__header {
      display: flex;
      gap: 6px;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      @media (min-width: 768px) {
        margin-bottom: 40px;
      }
    }
    &__settings {
      display: grid;
      gap: 20px;

      &--account {
        display: grid;
        gap: 20px;
      }

      &--networks {
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;

        @media (min-width: 768px) {
          gap: 40px;
          grid-template-columns: 1fr 1fr;
        }
      }
      &--notifications-links {
        display: grid;
        gap: 20px;

        &--chevron {
          height: 9px;
        }

        &--line {
          width: 100%;
          height: 1px;
          background-color: #c4c4c4;
        }

        &--button,
        &--link {
          padding: 0;
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
        }

        &--button {
          text-align: left;
          cursor: pointer;
        }

        &--link {
          text-decoration: none;
          color: unset;
        }
      }
    }
  }
</style>
