<script setup lang="ts">
  import type { ButtonBaseProps } from '~/types/design-system'
  import { useBrandingButton } from '~/composables/useBrandingButton.js'

  const props = withDefaults(
    defineProps<{
      trackSection: string
      type?: ButtonBaseProps['type']
      loading?: boolean
    }>(),
    {
      type: 'button',
      loading: false
    }
  )

  const emit = defineEmits<{
    register: []
  }>()

  const trackClick = () => {
    useTrack().track('click-to-sign-up', { section: props.trackSection })
  }

  const buttonSignUp = computed(() => useBrandingButton().extractButton('branding_general', 'signup_btn'))

  const storeStore = useStoreStore()
  const onClick = () => {
    trackClick()
    if (useEmbedded().isEmbedded.value) {
      const url = storeStore.store?.url
      if (storeStore.store?.provider === 1) {
        navigateExternalUrl(`${url}/account/register`)
      } else {
        navigateExternalUrl(`${url}/index.php?controller=my-account`)
      }
      return
    }

    emit('register')
  }
</script>

<template>
  <ButtonBranding
    :button-branding="buttonSignUp"
    :type
    :loading
    @click="onClick"
  >
    {{ buttonSignUp.text }}
  </ButtonBranding>
</template>
