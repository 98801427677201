<script setup lang="ts">
  import type { SocialAccountInfo } from '~/types/social-account'

  defineProps<{
    fromChallenge: boolean
  }>()

  const emit = defineEmits<{
    close: []
    edit: []
    retry: [username: string]
    'goto-challenge': []
  }>()

  const { data: creator } = useStoreCreator().fetchCreator()

  const account = computed((): SocialAccountInfo | undefined => {
    if (!creator.value || !creator.value.insta_account) return

    return {
      profilePictureUrl: creator.value.insta_account.profile_picture_url,
      followersCount: creator.value.insta_account.followers_count,
      username: creator.value.insta_account.insta_username,
      isPrivate: creator.value.insta_account.is_private
    }
  })

  const isAccountPrivate = computed(() => account.value?.isPrivate)

  const { modalsOpenedCount, isModalFullPage, isMobile } = storeToRefs(useStoreApp())

  const btn = computed(() => useBrandingButton().extractButton('branding_profil', 'action_btn'))
</script>

<template>
  <ModalApp
    show
    footer-fixed
    :class="{ 'child-modal': modalsOpenedCount > 0 && !isModalFullPage && !isMobile }"
    @close="emit('close')"
  >
    <div class="instagram-linked">
      <ModalSocialAccountTitle image-url="https://assets.loyoly.io/public/icons/instagram.png">
        {{ $t('Instagram.ModalLinked.title') }}
      </ModalSocialAccountTitle>

      <div class="instagram-linked__account">
        <span class="text-primary-heading-2 instagram-linked__account--title">
          {{
            isAccountPrivate
              ? $t('Instagram.ModalLinked.private.subtitle')
              : $t('Instagram.ModalLinked.public.subtitle')
          }}
        </span>

        <ModalSocialAccountInfo
          v-if="account"
          :account
        />
      </div>

      <div class="instagram-linked__description">
        <span class="text-primary-heading-2">
          {{
            isAccountPrivate
              ? $t('Instagram.ModalLinked.private.description.title')
              : $t('Instagram.ModalLinked.public.description.title')
          }}
        </span>
        <p class="text-secondary-heading-2">
          {{
            isAccountPrivate
              ? $t('Instagram.ModalLinked.private.description.text')
              : $t('Instagram.ModalLinked.public.description.text')
          }}
        </p>
      </div>
    </div>

    <template #footer>
      <ButtonBrandingInverted
        :button-branding="btn"
        @click="emit('edit')"
      >
        {{ $t('Instagram.ModalLinked.buttons.edit') }}
      </ButtonBrandingInverted>
      <ButtonBranding
        v-if="isAccountPrivate"
        :button-branding="btn"
        @click="emit('retry', account!.username)"
      >
        {{ $t('Instagram.ModalLinked.buttons.retry') }}
      </ButtonBranding>
      <ButtonBranding
        v-else-if="fromChallenge"
        :button-branding="btn"
        @click="emit('goto-challenge')"
      >
        {{ $t('Instagram.ModalLinked.buttons.gotoChallenge') }}
      </ButtonBranding>
      <ButtonBranding
        v-else
        :button-branding="btn"
        @click="emit('close')"
      >
        {{ $t('Instagram.ModalLinked.buttons.goback') }}
      </ButtonBranding>
    </template>
  </ModalApp>
</template>

<style scoped>
  .instagram-linked {
    display: grid;
    gap: 30px;

    &__account {
      &--title {
        display: block;
        margin-bottom: 10px;
      }
    }

    &__description {
      display: grid;
      gap: 10px;
    }
  }

  .child-modal {
    :deep(.lds-modal__container) {
      left: unset;
      top: unset;
      transform: unset;
    }
  }
</style>
