<script setup lang="ts">
  import type { CardProps } from '@wewiink/design-system/dist/types/card.js'
  import type { TypographyProps } from '@wewiink/design-system/dist/types/typography.js'
  import type { Reward } from '~/types/reward'
  import type { VipLevel } from '~/types/vip'

  const { isAuthenticated } = storeToRefs(useStoreAuth())
  const { companyId } = useRoute().params
  const { data: companyCreator } = useStoreCreator().getCompanyCreator(parseInt(companyId as string))
  const { $i18n } = useNuxtApp()
  const { getRewardFormattedText, getMoneyFormattedText } = useFormattedText()

  const props = defineProps<{
    vipLevels: VipLevel[]
  }>()

  const currentLevelIndex = computed((): number => {
    if (!isAuthenticated) return 0
    const currentLevel =
      Array.from(props.vipLevels)
        .sort((levelA, levelB) => (levelA.points_objective >= levelB.points_objective ? -1 : 1))
        .find((level) => level.points_objective <= (companyCreator?.value?.vip_points || 0)) || null

    const levelIndex = props.vipLevels.findIndex((l) => currentLevel?.id === l.id)
    if (levelIndex === null || levelIndex === -1) {
      return 0
    }
    return levelIndex
  })

  const levelGoal = computed(() => (level: VipLevel): string => {
    if (level.mode === 2) {
      return levelsDesign.value[level.id].subtitle.text.replace(
        '{{tier_goal}}',
        getMoneyFormattedText(level.points_objective)
      )
    } else {
      return levelsDesign.value[level.id].subtitle.text.replace(
        '{{tier_goal}}',
        $i18n.t('SectionVipTiersCard.tiersPoints', {
          amount: level.points_objective
        })
      )
    }
  })

  const levelsDesign = computed(() => {
    const { brandings } = useStoreBranding()

    const BrandingVipLevelsById = brandings!.branding_vip_level.reduce(
      (result: Record<string, TypographyProps | CardProps>, level) => {
        result[level.level_id] = {
          card_header: extractBrandingCard(level.card_header),
          card_content: extractBrandingCard(level.card_content),
          title: extractBrandingTypography(level.title, 'primary'),
          subtitle: extractBrandingTypography(level.subtitle, 'secondary'),
          content: extractBrandingTypography(level.content, 'secondary'),
          custom: extractBrandingTypography(level.custom, 'secondary')
        }
        return result
      },
      {}
    )

    return BrandingVipLevelsById
  })

  const getSortedRewards = (rewards: Reward[]) => {
    return [...rewards].sort((a, b) => Number(b.is_birthday) - Number(a.is_birthday)) // birthday rewards first
  }

  onMounted(() => {
    scrollToActiveLevel()
  })

  watch(currentLevelIndex, () => {
    scrollToActiveLevel()
  })

  const scrollToActiveLevel = async () => {
    const carouselSlider = document.querySelector('.vip-wrapper__carousel > :first-child')
    if (carouselSlider) carouselSlider.scrollLeft += (carouselSlider.clientWidth - 15) * currentLevelIndex.value
  }
</script>

<template>
  <template v-if="levelsDesign">
    <CardVip
      v-for="level in vipLevels"
      :id="level.id"
      :key="level.id"
      class="card-vip"
      :class="{ authenticated: isAuthenticated }"
      :card-image-url="level.icon_url"
      :card-top="levelsDesign[level.id].card_header"
      :card-bottom="levelsDesign[level.id].card_content"
      :card-title="levelsDesign[level.id].title"
      :card-objective="levelsDesign[level.id].subtitle"
      :card-rewards="levelsDesign[level.id].content"
      :card-custom="levelsDesign[level.id].custom"
    >
      <template #title>
        {{ level.name }}
      </template>
      <template #objective>
        {{ levelGoal(level) }}
      </template>
      <template
        v-if="levelsDesign[level.id].custom"
        #custom
      >
        {{ levelsDesign[level.id].custom.text }}
      </template>
      <template #rewards>
        <ul>
          <li v-if="level.reward_points > 0">
            {{ $t('SectionVipTiersCard.rewardPoints', { points: $n(level.reward_points) }) }}
          </li>
          <li v-if="level.multiplier > 1">
            {{ $t('SectionVipTiersCard.rewardMultiplier', { multiplier: $n(level.multiplier) }) }}
          </li>
          <li
            v-for="reward in getSortedRewards(level.rewards)"
            :key="reward.id"
          >
            {{ getRewardFormattedText(reward.name, reward.amount, 'local_currency_reward') }}
          </li>
        </ul>
      </template>
    </CardVip>
  </template>
</template>

<style scoped>
  .card-vip {
    min-width: 300px;

    @media (min-width: 480px) {
      min-width: 95%;
    }
  }
</style>
