<script setup lang="ts">
  import type { ChallengeDisplay } from '~/types/challenge'

  const props = defineProps<{
    display: ChallengeDisplay
  }>()

  const sources = computed(() => {
    if (!props.display.value) return
    return (props.display.value as string[]).filter((f) => f)
  })
</script>

<template>
  <div class="mission-media">
    <MediaExamples
      v-if="sources && sources?.length > 0"
      :sources="sources"
    />
  </div>
</template>
<style scoped>
  .mission-media {
    display: flex;
    justify-content: center;
  }
  :deep(.lds-media-example) {
    max-width: 100%;
  }
</style>
