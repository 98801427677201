<script setup lang="ts">
  import type { ClaimedReward } from '~/types/reward'

  const { t } = useI18n()

  const { data: creator } = useStoreCreator().fetchCreator()
  const storeStore = useStoreStore()
  const { isMobile } = storeToRefs(useStoreApp())
  const footerRef = ref()

  const props = defineProps<{
    claimedReward: ClaimedReward
    show: boolean
  }>()

  const emit = defineEmits<{
    close: []
  }>()

  onMounted(() => {
    useTrack().track('modal-rewards-claimed-detail', {
      email: creator.value?.email ?? '',
      reward_type: props.claimedReward.reward.promo_type
    })
  })

  const section = computed(() => {
    const { brandings } = useStoreBranding()

    if (!brandings) return

    const { extractTypography } = useBrandingTypography()
    const { extractButton } = useBrandingButton()

    return {
      card: brandings.branding_reward.card,
      rewardName: extractTypography('branding_reward', 'reward_text', 'primary'),
      rewardPoints: extractTypography('branding_reward', 'point_text', 'secondary'),
      actionButton: extractButton('branding_reward', 'action_btn')
    }
  })

  const onCopied = async () => {
    useTrack().track('clic-code-rewards-claimed-detail', {
      email: creator.value!.email,
      reward_type: props.claimedReward.reward.promo_type,
      reward_id: props.claimedReward.id
    })
  }

  const hasPromocodeReward = computed(() => {
    return !!props.claimedReward.promocode && !!props.claimedReward.promocode.reward
  })

  const isBadge = computed(() => {
    if (!hasPromocodeReward.value) return false
    return props.claimedReward.promocode.reward.promo_type === 'BAD'
  })

  const isDirectDistributionMode = computed(() => {
    if (!hasPromocodeReward.value) return false
    return props.claimedReward.promocode.reward.distrib_mode === 'DIR'
  })

  const isCustomReward = computed(() => {
    if (!hasPromocodeReward.value) return false
    return props.claimedReward.promocode.reward.distrib_mode === 'CUS'
  })

  const canAddToCart = computed(() => {
    return (
      useEmbedded().isEmbedded.value &&
      storeStore.store?.provider === 1 &&
      hasPromocodeReward &&
      props.claimedReward.promocode.reward.is_external &&
      ['SHI', 'PER', 'BON', 'PRO'].includes(props.claimedReward.promocode.reward.promo_type)
    )
  })

  const addToCart = () => {
    useTrack().track('clic-apply-rewards-claimed-detail', {
      email: creator.value!.email,
      reward_type: props.claimedReward.reward.promo_type,
      reward_id: props.claimedReward.id
    })

    storeStore.addRewardToCart(props.claimedReward)
  }

  const openLink = () => {
    if (useEmbedded().isEmbedded) {
      navigateExternalUrl(props.claimedReward.promocode.reward.website_url)
    } else {
      window.location.href = props.claimedReward.promocode.reward.website_url
    }
  }

  const howItWorksText = () => {
    if (isDirectDistributionMode.value) return t('SectionRewardsClaimModal.howItWorkExt')
    else if (hasPromocodeReward.value && props.claimedReward.promocode.reward.is_recharge)
      return t('SectionRewardsClaimModal.howItWorkCodeRecharge')
    else if (hasPromocodeReward.value && props.claimedReward.promocode.reward.promo_type !== 'PRO')
      return t('SectionRewardsClaimModal.howItWorkCode')
    else return t('SectionRewardsClaimModal.howItWorkCodeProduct')
  }

  const shouldTeleportBtnsToFooter = computed(() => {
    return footerRef.value && isMobile.value
  })
</script>

<template>
  <ModalApp
    class="reward-claim-modal"
    footer-fixed
    :no-footer="isDirectDistributionMode || isBadge || !isMobile"
    :show
    @close="emit('close')"
  >
    <SectionRewardsDetailInfos
      claim-context
      :expiration-date="claimedReward.expiration_date ? String(claimedReward.expiration_date) : null"
      :reward="claimedReward.reward"
    >
      <div
        v-if="!isBadge"
        class="text-secondary-body"
      >
        <AccordionBase @vue:updated="useModalResize().update">
          <template #header>
            <span class="text-primary-heading-2">{{ $t('SectionRewardsClaimModal.howItWorks') }}</span>
          </template>
          <div class="text-secondary-heading-2">
            {{ howItWorksText() }}
          </div>
        </AccordionBase>
      </div>
      <template #btn>
        <ClientOnly>
          <Teleport
            :to="footerRef"
            :disabled="!shouldTeleportBtnsToFooter"
          >
            <div
              v-if="!isDirectDistributionMode && !isBadge"
              class="reward-claim-modal__btn"
            >
              <ButtonCopy
                v-if="claimedReward.promocode.name"
                :radius="section!.actionButton.radius"
                :background-color="section!.actionButton.textColor"
                :hover-background-color="section!.actionButton.hoverTextColor"
                :font-size="section!.actionButton.fontSize"
                :text-color="section!.actionButton.backgroundColor"
                :hover-text-color="section!.actionButton.hoverBackgroundColor"
                :weight="section!.actionButton.weight"
                :border-color="section!.actionButton.backgroundColor"
                :copy-text="claimedReward.promocode.name"
                :copied-text="t('SectionRewardsClaimModal.copied')"
                :content-to-copy="claimedReward.promocode.name"
                @copy="onCopied"
              />
              <ButtonBranding
                v-if="canAddToCart"
                :button-branding="section!.actionButton"
                type="submit"
                @click="addToCart"
              >
                {{ $t('SectionRewardsClaimModal.apply') }}
              </ButtonBranding>
              <ButtonBranding
                v-if="isCustomReward && claimedReward.promocode.reward.website_url"
                :button-branding="section!.actionButton"
                @click="openLink"
              >
                {{ $t('SectionRewardsClaimModal.link') }}
              </ButtonBranding>
            </div>
          </Teleport>
        </ClientOnly>
      </template>
    </SectionRewardsDetailInfos>
    <template #footer>
      <div
        ref="footerRef"
        class="reward-claim-modal__footer"
      />
    </template>
  </ModalApp>
</template>

<style scoped>
  .reward-claim-modal {
    &__footer {
      width: 100%;
      text-align: center;
    }
    &__btn {
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media (min-width: 768px) {
        flex-direction: column;
      }

      @media (min-width: 1024px) {
        flex-direction: row;
      }
    }
  }
</style>
