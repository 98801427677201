import { useMutation, useQueryClient } from '@tanstack/vue-query'
import type { InstagramAccountSetParams } from '~/types/social-account'

export const useStoreInstagram = defineStore('accountInstagram', () => {
  const queryClient = useQueryClient()

  const setInstagramAccount = () => {
    return useMutation({
      mutationFn: async (form: InstagramAccountSetParams) => {
        const username = form.instaUsername.startsWith('@') ? form.instaUsername.substring(1) : form.instaUsername
        await useNuxtApp().$api(`/api/creator/insta-accounts/`, {
          method: 'POST',
          body: {
            insta_username: username,
            user_insta_account: [form.creatorId]
          }
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['fetchCreator'] })
      },
      onError: () => {
        queryClient.invalidateQueries({ queryKey: ['fetchCreator'] })
      }
    })
  }

  const refreshInstagramAccount = async (accountId: number) => {
    return await useNuxtApp().$api(`api/creator/insta-accounts/${accountId}/refresh/`, {
      method: 'POST'
    })
  }

  const clearInstagramAccount = async (accountId: number) => {
    return await useNuxtApp().$api(`/api/creator/insta-accounts/${accountId}/`, {
      method: 'DELETE'
    })
  }

  return { setInstagramAccount, refreshInstagramAccount, clearInstagramAccount }
})
