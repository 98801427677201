<script setup lang="ts">
  import type { Company } from '~/types/company'
  import type { Creator } from '~/types/creator'

  const { extractTypography } = useBrandingTypography()
  const { extractButton } = useBrandingButton()
  const { getCreatorBalance } = useStoreCreator()
  const { company } = storeToRefs(useStoreCompany())

  const { companyId } = useRoute().params
  const { data: balance } = getCreatorBalance(parseInt(companyId as string, 10))
  const { data: creator } = useStoreCreator().fetchCreator()

  const shouldHandleExpiration = computed(() => {
    if (useRuntimeConfig().public.featureFlagExpirationDate !== 'true') return false

    const { expiration_mode } = company.value as Company
    return expiration_mode && expiration_mode > 0 // O = no limit
  })

  const stepConnected = computed(() => {
    const title = extractTypography('branding_step', 'title_connected', 'primary')
    const subtitle = extractTypography('branding_step', 'subtitle_connected', 'secondary')
    const expiration = shouldHandleExpiration.value ? getExpiration() : undefined

    let firstName = ''
    if (creator.value) {
      firstName = (creator.value as Creator).first_name
    }

    title.text = title.text!.replace('{{first_name}}', firstName)

    subtitle.text = subtitle.text!.replace('{{points_amount}}', String(balance.value))

    return {
      title,
      subtitle,
      expiration,
      buttonReward: extractButton('branding_step', 'reward_btn'),
      buttonActivity: extractButton('branding_step', 'activity_btn')
    }
  })

  const { t, d } = useI18n()

  const getExpiration = () => {
    const expiration = extractTypography('branding_step', 'expiration_connected', 'secondary')

    const { expiration_mode, expiration_month, expiration_date } = company.value as Company

    if (expiration_mode === 2) {
      expiration.text = expiration.text.replace('{{expiration_date_points}}', d(new Date(expiration_date!), 'short'))
    }

    // expiration_mode = 1
    expiration.text = expiration.text.replace('{{delay}}', t('SectionSteps.Authenticated.month', expiration_month!))

    return expiration
  }
</script>

<template>
  <StepConnected
    :step-title="stepConnected.title"
    :step-subtitle="stepConnected.subtitle"
    :step-expiration="stepConnected.expiration"
  >
    <template #title>
      {{ stepConnected.title.text }}
    </template>
    <template #subtitle>
      {{ stepConnected.subtitle.text }}
    </template>
    <template
      v-if="shouldHandleExpiration && stepConnected.expiration"
      #expiration
    >
      {{ stepConnected.expiration.text }}
    </template>
    <SectionStepsButtonRewards />
    <SectionStepsButtonActivities />
  </StepConnected>
</template>
