<script setup lang="ts">
  import type { Reward } from '~/types/reward'

  const { getRewardFormattedText, getMoneyFormattedText } = useFormattedText()

  defineProps<{
    reward: Reward
    claimContext?: boolean
    expirationDate?: string | null
  }>()
</script>

<template>
  <div class="reward-infos">
    <ImageBase
      class="reward-infos__image"
      :src="reward.picture_url_1"
    />
    <div class="reward-infos__content">
      <h1 class="text-primary-heading-1">
        {{ getRewardFormattedText(reward.name, reward.amount, 'local_currency_reward') }}
      </h1>
      <Points
        class="reward-infos__points"
        :points="reward.threshold"
      />
      <div>
        <div
          v-if="(claimContext && expirationDate) || reward.validity_date"
          class="reward-infos__info text-primary-heading-2"
        >
          {{ $t('SectionRewardsDetailInfos.expireDate') }}
          <span>{{ $d(new Date(expirationDate ?? reward.validity_date), 'short') }}</span>
        </div>
        <div
          v-if="!claimContext && reward.validity_delay > 0"
          class="reward-infos__info text-primary-heading-2"
        >
          {{ $t('SectionRewardsDetailInfos.expireDate') }}
          <span>
            {{
              $t('SectionRewardsDetailInfos.expirationDelayExplain', {
                expiration_delay: reward.validity_delay
              })
            }}
          </span>
        </div>
        <div
          v-if="reward.prerequisite_amount"
          class="reward-infos__info text-primary-heading-2"
        >
          {{ $t('SectionRewardsDetailInfos.minimumBuy') }}
          <span>
            {{ getMoneyFormattedText(reward.prerequisite_amount) }}
          </span>
        </div>
        <div
          v-if="reward.shipping_max_amount"
          class="reward-infos__info text-primary-heading-2"
        >
          {{ $t('SectionRewardsDetailInfos.maxAmount') }}
          <span>
            {{ getMoneyFormattedText(reward.shipping_max_amount) }}
          </span>
        </div>
        <slot />
        <div
          v-if="reward.description"
          class="text-secondary-body"
        >
          <AccordionBase
            :model-value="false"
            @vue:updated="useModalResize().update"
          >
            <template #header>
              <span class="text-primary-heading-2">{{ $t('SectionRewardsDetailInfos.description') }}</span>
            </template>
            <div class="text-secondary-heading-2">{{ reward.description }}</div>
          </AccordionBase>
        </div>
      </div>
      <slot name="btn" />
    </div>
  </div>
</template>
<style scoped>
  .reward-infos {
    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 40px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &__image {
      margin-bottom: 20px;
      object-fit: cover;
      max-height: 250px;
      width: 250px;

      @media (min-width: 768px) {
        max-height: 300px;
        width: 300px;
        margin-bottom: 0px;
      }

      @media (min-width: 1024px) {
        max-height: 400px;
        width: 400px;
        margin-bottom: 0px;
      }
    }
    &__info {
      padding: 20px 0px;
      border-bottom: 1px solid var(--gray-100);

      span {
        margin-left: 10px;
        font-weight: 400;
      }
    }
  }
</style>
