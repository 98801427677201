<script setup lang="ts">
  import { useI18n } from 'vue-i18n'
  import type { CreatorActivity } from '~/types/creator'

  const emit = defineEmits<{
    close: []
  }>()

  const { t } = useI18n()
  const { companyId } = useStoreApp()
  const { data: activities, isPending } = useStoreCreator().getCreatorActivities(companyId, 5)

  const { data: claimedRewards } = useStoreReward().getClaimedRewards(companyId)
  const getRewardLabel = (activity: CreatorActivity) => {
    if (!claimedRewards.value) return

    const claim = claimedRewards.value.find((reward) => String(reward.id) === activity.object_id)
    return claim?.reward?.name
  }

  const { data: challenges } = useStoreChallenge().getChallenges(companyId)
  const getChallengeLabel = (activity: CreatorActivity) => {
    if (!challenges.value) return

    const challenge = challenges.value.find((challenge) => {
      if (!challenge.challenge_creator_ids) return

      return challenge.challenge_creator_ids.map((id) => String(id)).includes(activity.object_id)
    })

    return challenge?.name
  }

  const { data: levels } = useStoreVip().getVipLevels(companyId)
  const getLevelLabel = (activity: CreatorActivity) => {
    if (!levels.value) return

    const level = levels.value.find((level) => String(level.id) === activity.object_id)

    if (!level) return

    return t('SectionSteps.ModalActivities.events.levelLabel', { level: level.name })
  }

  const eventLabelsMap = new Map()
  eventLabelsMap.set(0, { label: t('SectionSteps.ModalActivities.events.challenge'), labelMethod: getChallengeLabel })
  eventLabelsMap.set(1, { label: t('SectionSteps.ModalActivities.events.order') })
  eventLabelsMap.set(2, { label: t('SectionSteps.ModalActivities.events.reward'), labelMethod: getRewardLabel })
  eventLabelsMap.set(3, { label: t('SectionSteps.ModalActivities.events.refferal') })
  eventLabelsMap.set(4, { label: t('SectionSteps.ModalActivities.events.userAction') })
  eventLabelsMap.set(5, { label: t('SectionSteps.ModalActivities.events.level'), labelMethod: getLevelLabel })
  eventLabelsMap.set(6, { label: t('SectionSteps.ModalActivities.events.reset') })

  const getLabel = (activity: CreatorActivity) => {
    const { labelMethod, label } = eventLabelsMap.get(activity.type_event)

    if (!labelMethod) return label

    return labelMethod(activity) || label
  }

  const { data: creator } = useStoreCreator().fetchCreator()
  onMounted(() => {
    useTrack().track('modal-recent-activities', {
      email: creator.value!.email
    })
  })
</script>

<template>
  <ModalApp
    show
    no-footer
    @close="emit('close')"
  >
    <h1 class="text-primary-heading-1">{{ $t('SectionSteps.ModalActivities.title') }}</h1>

    <div
      v-if="isPending || !activities?.results"
      class="activities-pending"
    >
      <IconLoader
        color="#000"
        width="48px"
        height="48px"
      />
    </div>
    <TableBase
      v-else-if="activities.results.length"
      grid-template-columns="1fr 120px 120px"
    >
      <TableCellHeader>
        {{ $t('SectionSteps.ModalActivities.table.header.title') }}
      </TableCellHeader>
      <TableCellHeader>
        {{ $t('SectionSteps.ModalActivities.table.header.points') }}
      </TableCellHeader>
      <TableCellHeader>
        {{ $t('SectionSteps.ModalActivities.table.header.date') }}
      </TableCellHeader>
      <template
        v-for="(activity, idx) in activities.results"
        :key="`activity_${idx}`"
      >
        <TableCell>{{ getLabel(activity) }}</TableCell>
        <TableCell>{{ activity.amount }}</TableCell>
        <TableCell>{{ $d(new Date(activity.created_at), 'short') }}</TableCell>
      </template>
    </TableBase>
    <SectionEmpty v-else>
      {{ $t('SectionSteps.ModalActivities.empty') }}
    </SectionEmpty>
  </ModalApp>
</template>

<style scoped>
  .activities-pending {
    height: 100%;
    display: grid;
    place-items: center;
  }

  :deep(.lds-modal__content) {
    height: 100%;
  }
</style>
